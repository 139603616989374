
// @ is an alias to /src

import FlatButton from "@/components/atomics/FlatButton.vue";
import { defineComponent } from "vue";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "PasswordEmailConfirm",
  components: {
    FlatButton,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const goNext = () => {
      router.push({
        path: "/forgot-password/password-new",
        query: { code: route.query.code as string },
      });
    };

    return {
      goNext,
    };
  },
});
